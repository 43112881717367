import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../views/Home"
import PrivacyPolicy from "../views/PrivacyPolicy"
import TermsOfService from "../views/TermsOfService"
import NotFound from "../views/NotFound";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
