import React from "react";

const PricingContent = [
  {
    packName: "Essential",
    packDetails: "For individual contributors",
    strikePrice: "39",
    price: "19",
    features: [
      "10 Project Creations/Mo",
      "Basic Project Analysis",
      "No Uploads",
      "Basic Support",
    ],
    animationDelay: "",
    activeClass: "",
    icon: "images/icon-plan-essential.png",
  },
  {
    packName: "Professional",
    packDetails: "For production-level professionals",
    strikePrice: "69",
    price: "39",
    features: [
      "25 Project Creations/Mo",
      "AI Project Analysis",
      "10K Upload Character Limit",
      "Premium Support",
    ],
    animationDelay: "150",
    activeClass: "active most-popular",
    icon: "images/icon-plan-professional.png",
  },
  {
    packName: "Advanced",
    packDetails: "For major decision-makers",
    strikePrice: "149",
    price: "99",
    features: [
      "100 Project Creations/Mo",
      "AI Project Analysis",
      "50K Upload Character Limit",
      "Premium Support",
    ],
    animationDelay: "300",
    activeClass: "",
    icon: "images/icon-plan-advanced.png",
  },
];

const Pricing = ({isReferral}) => {
  let trialElement = <li>5-Day Free Trial</li>
  if (isReferral === true) {
    trialElement = <li>15-Day Free Trial</li>
  }


  return (
    <div className="row justify-content-center">
      {PricingContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.animationDelay}
          key={i}
        >
          <div className={`pr-table-wrapper mt-40 ${val.activeClass}`}>
            <div className="top-banner d-md-flex align-items-center">
                <div className="col-lg-8" style={{ paddingLeft: '0px'}}>
                    <div className="pack-name">{val.packName}</div>
                    <div style={{display: 'flex', marginTop: '10px'}}>
                      <div className="price">
                          <sup style={{fontSize: '16px', paddingRight: '2px'  }}>$</sup>
                          <span style={{ display: 'inline', textDecoration: 'line-through' }}>{val.strikePrice}</span>
                          <sup style={{fontSize: '16px', paddingLeft: '4px', paddingRight: '2px'}}>$</sup>
                          <span style={{ display: 'inline'   }}>{val.price}</span>
                      </div>
                      <div className="sub-price">
                          <em>/mo</em>
                      </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <img src={val.icon} alt="logo" height="80" style={{ maxWidth: '150%'}}/>
                </div>
            </div>
            <div className="pack-details" style={{paddingBottom: '10px', minHeight: '80px'}}>{val.packDetails}</div>
            
            <a href="https://app.rdyai.com/signup" className="trial-button theme-btn-orange tran3s">
              Start Free Trial
            </a>
            <ul className="pr-feature">
              {trialElement}
              {val.features.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>
          </div>
          {/* /.pr-table-wrapper */}
        </div>
      ))}
    </div>
  );
};

export default Pricing;
