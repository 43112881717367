import React from "react";

const FooterCopyright = () => {
  return (
    <div className="row">
      <div className="col-lg-12 order-lg-2">
        <p className="copyright text-center">
          © {new Date().getFullYear()} Rdy
        </p>
      </div>
    </div>
  );
};

export default FooterCopyright;
