import React, { useState } from "react";

const BottomCta = () => {
  const [email, setEmail] = useState();

  const handleRedirect = (event) => {
    event.preventDefault();
        // TODO: Improve email validation
        window.location.href = "https://app.rdyai.com/signup?email=" + email
  };

  return (
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="title-style-one">
          <h6 className="font-rubik" style={{ color: "#f96f60" }}>
            Sign up for free
          </h6>
          <h2>Let's make life a bit easier. <br/>Start here.</h2>
        </div>
      </div>

      <div className="col-lg-6">
        <div className="form-wrapper">
          <form onSubmit={handleRedirect}>
            <input type="email" placeholder="Enter your email..." onChange={(event) => setEmail(event.target.value)}/>
            <button className="theme-btn-orange" type="submit">Sign up</button>
          </form>
          {/* End form */}
          <p className="font-rubik">
            Already have an account? <a href="https://app.rdyai.com/login">Login</a>
          </p>
        </div>
        {/* /.form-wrapper */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default BottomCta;
