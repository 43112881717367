import React from "react";

const IntroductoryBlocks = () => {
    return(
      <div className="fancy-feature-sixteen pt-60 md-pt-100" id="how-it-works">
      <div className="container">
        <div className="block-style-eighteen">
          <div className="row align-items-center">
            <div
              className="col-lg-5"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <h6 style={{ marginLeft: '4px'}}>Gather the right information</h6>
                <h3 className="title">
                  Come prepared to <span>every project</span>
                </h3>
                <p>
                  We ask the right questions at the right time to ensure your projects are comprehensive and complete to ensure your success.
                </p>
              </div>
            </div>
            <div
              className="col-lg-7"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div
                className="
                  screen-holder-one
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <div
                  className="
                    round-bg
                    big_g
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                style={{ width: '80%' }}
                >
                  <img src="images/intro-blocks-1.png" alt="Come prepared to every project" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.block-style-eighteen */}

        <div className="block-style-eighteen mt-40 pt-50 md-mt-80">
          <div className="row align-items-center">
            <div
              className="col-lg-7 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <h6 style={{ marginLeft: '4px'}}>Augment your knowledge</h6>
                <h3 className="title">
                  Leverage the power of <span>AI</span>
                </h3>
                <p>
                  Our AI-driven analysis engine helps pinpoint weaknesses in your approach, providing actionable and constructive feedback to help optimize your decisions.
                </p>
              </div>
            </div>
            <div
              className="col-lg-5 order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="screen-holder-two">
                <img src="images/intro-blocks-2-1.png" alt="screen" style={{ width: '260px' }}/>
                <img
                  src="images/intro-blocks-2-2.png"
                  alt=""
                  className="shapes screen-one"
                />
              </div>
            </div>
          </div>
        </div>
        {/* /.block-style-eighteen */}

        <div className="block-style-eighteen mt-40 pt-50 md-mt-80">
          <div className="row align-items-center">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <h6 style={{marginLeft: '4px'}}>Save days of complications</h6>
                <h3 className="title">
                  Make better decisions, <span>move faster</span>
                </h3>
                <p>
                  Maximize team efficiency by identifying and clarifying the most important aspects of your project sooner rather than later.
                </p>
                <a href="https://app.rdyai.com/signup" className="theme-btn-eight theme-btn-orange">
                  Try for Free!
                </a>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div
                className="
                  screen-holder-three
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <img src="images/intro-blocks-3.png" alt="screen" style={{ width: '460px' }}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
};

export default IntroductoryBlocks;
