import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/rdy/Header";
import HeroBanner from "../components/rdy/HeroBanner";
import Pricing from "../components/rdy/Pricing";
import FeatureBlocks from "../components/rdy/FeatureBlocks";
import FooterCopyright from "../components/rdy/FooterCopyright";
import Footer from "../components/rdy/Footer";
import ThreePanel from "../components/rdy/ThreePanel";
import Faq from "../components/rdy/Faq";
import FeaturesTabs from "../components/rdy/FeaturesTabs";
import BottomCta from "../components/rdy/BottomCta";
import IntroductoryBlocks from "../components/rdy/IntroductoryBlocks"

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { useNavigate, useLocation } from "react-router-dom";
  import Cookies from 'js-cookie';

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  
  const refId = queryParams.get("ref_id");
  const localRefId = localStorage.getItem('ref_id')
  const [shownReferral, setShownReferral] = React.useState(false);

  let isReferral = false

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  // This should be a cookie check, yes? 

  if (localRefId) {
    isReferral = true
  } else if (refId !== undefined && refId !== null) {
    localStorage.setItem('ref_id', refId)
    Cookies.set('referral_id', refId, { domain: '.rdyai.com', path: '/' });
    isReferral = true
  }


  useEffect(() => {
      // Make sure this only happens once per page load
      const toastFragment = <React.Fragment>
        <div style={{ color: '#101621', marginBottom: '6px', textAlign: 'center' }}>👋 Welcome to Rdy! 👋</div>
        <div style={{ fontSize: '16px', marginBottom: '8px', lineHeight: '26px', color: '#585e69', textAlign: 'center' }}>Thank you for being an early supporter!</div>
        <div style={{ fontSize: '16px', lineHeight: '26px', color: '#585e69', textAlign: 'center' }}>Here's a 15-day free trial and up to 50% off, just for you ❤️</div>
      </React.Fragment>

      if (isReferral && !shownReferral) {
          toast.success(toastFragment, {
              icon: false,
              position: "bottom-right",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              style: { borderRadius: '12px'}
              });
          setShownReferral(true)
          navigate("/")
      }
      
    }, [isReferral, navigate, shownReferral]);

  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title>
          {" "}
          Rdy - Deliver Better
        </title>
      </Helmet>

      <ToastContainer style={{ width: '360px'}}/>

      <Header />

      <div id="home">
        <HeroBanner />
      </div>

      <IntroductoryBlocks />


      <div className="fancy-feature-fifteen pt-40 md-pt-80" id="features">
        <div className="bg-wrapper">
          <div className="container">
            <div className="sldier-wrapper">
              <FeaturesTabs />
            </div>
          </div>
        </div>
      </div>
 
 
      <div className="fancy-text-block-four pt-130 pb-120 md-pt-100 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8">
              <div className="title-style-one mb-40 md-mb-20">
                <h2>For any project, big or small!</h2>
                <p className="font-rubik">
                    Rdy is built to be used at any level of experience, from individual contributors to directors, from engineers to human resources, from entrepreneurs to corporations.
                </p>
              </div>
            </div>
          </div>

          <div className="wrapper">
            <div className="row justify-content-center">
              <FeatureBlocks />
            </div>
          </div>
        </div>
      </div>


      <div className="fancy-feature-five mt-40 md-mt-40">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <img
            src="images/rdy-shapes/47.svg"
            alt="shape"
            className="shapes shape-five"
          />
          <div className="container">
            <div className="title-style-two text-center mb-20 md-mb-20">
              <div className="row">
                <div className="col-xl-8 col-lg-9 m-auto">
                  <h2>Why Rdy?</h2>
                  <div className="sub-text">
                    The life of a startup involves making choices. We may choose right, we may choose wrong, or we may not realize our mistakes until it's too late.
                  </div>
                  <div className="sub-text">
                    Let's fix that.
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mb-30">
              <ThreePanel />
            </div>
          </div>
        </div>
      </div>


      <div
        className="fancy-text-block-twentyThree lg-container pt-40 mt-30 md-pt-90"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 order-lg-last" data-aos="fade-left">
              <div className="text-wrapper text-center">
                <div className="title-style-twelve">
                  <h2 style={{fontSize: '46px'}}>Plan smarter, build quicker, <span>deliver better<img src="images/rdy-shapes/line-shape-12.svg" alt="shape" className="cs-screen" style={{bottom:'-6px'}}/></span></h2>
                  <p className="mt-35 mb-60 md-mb-40">
                    It's time to invest in yourself. Give Rdy a try for a project you're already working on, or for any future plans in the pipeline.
                  </p>
                </div>
                <a href="https://app.rdyai.com/signup" className="theme-btn-eight theme-btn-orange">
                  Try for Free!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div
        className="pricing-section-nine lg-container mb-60 pt-80 md-pt-110"
        id="pricing"
      >
        <div className="container">
          <div
            className="title-style-eleven text-center"
            data-aos="fade-up"
          >
            <h2 style={{ fontSize: '48px' }}>Our Plans</h2>
            <div className="upper-title" style={{ fontSize: '16px' }}>All plans include a 5-day free trial</div>
            {isReferral && <div className="upper-title" style={{ fontSize: '12px', lineHeight: '16px' }}>(But we made it 15 days, just for you! 😉)</div>}
          </div>

          <div className="row justify-content-center">
            <Pricing 
              isReferral={isReferral}
            />
          </div>
        </div>
      </div>


      <div className="faq-classic with-bg" id="faq">
        <img
          src="images/rdy-shapes/86.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/rdy-shapes/87.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <img
          src="images/rdy-shapes/88.svg"
          alt="shape"
          className="shapes shape-three"
        />
        <img
          src="images/rdy-shapes/89.svg"
          alt="shape"
          className="shapes shape-four"
        />
        <img
          src="images/rdy-shapes/90.svg"
          alt="shape"
          className="shapes shape-five"
        />
        <img
          src="images/rdy-shapes/91.svg"
          alt="shape"
          className="shapes shape-six"
        />
        <div className="container">
          <div className="title-style-four text-center mt-20 mb-100 md-mb-70">
            <div className="row">
              <div className="col-lg-12 m-auto">
                <h2 style={{ fontSize: '48px' }}>
                  <span>
                    Frequently Asked Questions
                    <img src="images/rdy-shapes/line-shape-2.svg" alt="" style={{ bottom: '-30px'}} />
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <Faq />
        </div>
      </div>


      <div className="fancy-short-banner-three mt-60 md-mt-40">
        <div className="container">
          <div className="bg-wrapper">
            <BottomCta />
          </div>
        </div>
      </div>


      <footer className="theme-footer-seven mt-80 md-mt-100">
        <div className="lg-container">
          <div className="container">
            <Footer />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <FooterCopyright />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
