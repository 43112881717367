import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import Modal from "react-modal";

Modal.setAppElement("#root");

const logo = "images/logo-rdy-256.png";

const HeaderLanding = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-five fixed"
            : "theme-main-menu sticky-menu theme-menu-five"
        }
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
              <img src={logo} alt="Rdy AI" width="150"/>
          </div>

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div className="mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                  <div className={click ? "active" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={[
                      "home",
                      "how-it-works",
                      "features",
                      "pricing",
                      "faq",
                    ]}
                    currentClassName="active"
                    offset={-500}
                  >
                    <li className="nav-item position-static">
                      <a
                        className="nav-link"
                        href="#home"
                      >
                        Home
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href="#how-it-works" className="nav-link">
                        How it works
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#features" className="nav-link">
                        Features
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#pricing" className="nav-link">
                        Pricing
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#faq" className="nav-link">
                        FAQ
                      </a>
                    </li>
                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
          <div className="right-widget">
            <ul className="d-flex align-items-center">
              <li>
                <div style={{marginRight: '12px'}}>
                    <a href="https://app.rdyai.com/login" className="nav-link signIn-action d-flex align-items-center">
                      login
                    </a>
                </div>
                
              </li>

              <li>
                <a href="https://app.rdyai.com/signup" className="nav-link demo-button">
                  Signup
                </a>
              </li>
            
            </ul>
            
          </div>
        </div>
      </div>

      <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
        <div className="logo order-md-1">
          <img src={logo} alt="Rdy" width="140"/>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="images/rdy-icons/close.svg" alt="icon" />
          </div>
        </div>

        <Scrollspy
          className="navbar-nav"
          id="theme-menu-list"
          items={[
            "home",
            "how-it-works",
            "features",
            "pricing",
            "faq",
          ]}
          currentClassName="active"
          offset={-200}
        >
          <li className="nav-item">
            <a href="#home" className="nav-link" onClick={handleClick}>
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="#how-it-works" className="nav-link" onClick={handleClick}>
              How it works
            </a>
          </li>
          <li className="nav-item">
            <a href="#features" className="nav-link" onClick={handleClick}>
              Features
            </a>
          </li>
          <li className="nav-item">
            <a href="#pricing" className="nav-link" onClick={handleClick}>
              Pricing
            </a>
          </li>
          <li className="nav-item">
            <a href="#faq" className="nav-link" onClick={handleClick}>
              FAQ
            </a>
          </li>
          <li className="nav-item">
            <a href="https://app.rdyai.com/login" className="nav-link">
              Login
            </a>
          </li>
          <li className="nav-item">
            <a href="https://app.rdyai.com/signup" className="nav-link">
              Signup
            </a>
          </li>
        </Scrollspy>
      </div>
    </>
  );
};

export default HeaderLanding;
