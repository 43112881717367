import React, { useState } from "react";

const CtaForm = () => {
  const [email, setEmail] = useState();

  const handleRedirect = (event) => {
    event.preventDefault();
    // TODO: Improve email validation
    window.location.href = "https://app.rdyai.com/signup?email=" + encodeURIComponent(email)
  };

  return (
    <form onSubmit={handleRedirect}>
      <input type="email" placeholder="Enter your email..." onChange={(event) => setEmail(event.target.value)}/>
      <button type="submit">Try for free</button>
    </form>
  );
};

export default CtaForm;
