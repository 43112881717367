import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const FeaturesTabs = () => {
  return (
    <>
      <Tabs>
        <TabList>
          <Tab>
            <div className="d-flex align-items-center">
            <img src="images/rdy-icons/51.svg" alt="icon" width="18" />
              <p>Project Examination</p>
            </div>
          </Tab>
          {/* End .d-felx */}
          <Tab>
            <div className="d-flex align-items-center ">
              <img src="images/rdy-icons/95.svg" alt="icon" width="24" />
              <p>Document Analysis</p>
            </div>
          </Tab>
          {/* End .d-felx */}
          <Tab>
            <div className="d-flex align-items-center">
              <img src="images/rdy-icons/75.svg" alt="icon" width="18"/>
              <p>Constructive Results</p>
            </div>
          </Tab>
          {/* End .d-felx */}
        </TabList>

        <div className="mt-50 sm-mt-30">
          <TabPanel>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <img
                  src="images/features-tab-1.png"
                  alt="screen"
                  className="m-auto"
                  data-aos="fade"
                  data-aos-duration="1200"
                />
              </div>
              <div className="col-md-5">
                <p
                  className="feature-block-text-meta"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                  style={{ marginTop: '48px'}}
                >
                  For every project you're working on, our goal is to help you shine a light on the spots you missed.
                </p>

                <p
                  className="feature-block-text-meta"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  We want you to go into every project as confident as you can be that everything will go as planned.
                </p>

                <p
                  className="feature-block-text-meta"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  We're here to make sure you're ready.
                </p>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <p
                    className="feature-block-text-meta"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="100"
                    style={{ marginTop: '48px'}}
                  >
                    Our AI analysis engine will read through your project documents to narrow down the areas that need the most focus.
                  </p>

                  <p
                    className="feature-block-text-meta"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="300"
                  >
                    With your collaboration, we help you understand exactly where you are and where to go next.
                  </p>
              </div>
              <div className="col-md-5">
                <img
                  src="images/features-tab-2.png"
                  alt="screen"
                  className="m-auto"
                  data-aos="fade"
                  data-aos-duration="1200"
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
          <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <img
                  src="images/features-tab-3.png"
                  alt="screen"
                  className="m-auto"
                  data-aos="fade"
                  data-aos-duration="1200"
                />
              </div>
              <div className="col-md-5">
                <p
                  className="feature-block-text-meta"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                  style={{ marginTop: '48px'}}
                >
                  Lastly, your inputs are translated into constructive and practical recommendations to help you advance your project.
                </p>

                <p
                  className="feature-block-text-meta"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  Every point of data we utilize is meant to be one more possible improvement that can help you reach your goals.
                </p>
              </div>
            </div>
          </TabPanel>
        </div>
        {/* /.pricing-table-area-six */}
      </Tabs>
    </>
  );
};

export default FeaturesTabs;
