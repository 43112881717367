import React from "react";

const FeatureContent = [
  {
    icon: "images/icon-document.png",
    meta: "Save Time & Resources",
    subTitle: `Save countless hours of wasted time and burnt resources`,
    dataDelay: "0",
  },
  {
    icon: "images/icon-fire.png",
    meta: "Dodge Disasters",
    subTitle: `Spot problems before they happen and spare yourself the headaches`,
    dataDelay: "100",
  },
  {
    icon: "images/icon-rocket.png",
    meta: "Upgrade Yourself",
    subTitle: `Sharpen your critical-thinking skills and excel in your role`,
    dataDelay: "300",
  },
];

const ThreePanel = () => {
  return (
    <>
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-seven">
            <div className="icon">
              <img src={val.icon} alt="icon" />
            </div>
            <div className="feature-info">
              <span>{val.meta}</span>
            </div>
            <p className="font-rubik">{val.subTitle}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default ThreePanel;
