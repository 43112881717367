import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import Modal from "react-modal";
import {useNavigate} from "react-router-dom";

Modal.setAppElement("#root");

const logo = "images/logo-rdy-256.png";

const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const navigate = useNavigate();

  const onHome = (e) => {
      e.preventDefault();
      navigate("/")
  }

  const onPrivacyPolicy = (e) => {
      e.preventDefault();
      navigate("/privacy-policy")
  }

  const onTerms = (e) => {
      e.preventDefault();
      navigate("/terms")
  }

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-five fixed"
            : "theme-main-menu sticky-menu theme-menu-five"
        }
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
            
              <img src={logo} alt="Rdy AI" width="150"/>
          </div>
          {/* End logo */}

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div className="mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                  <div className={click ? "active" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={[
                      "home",
                      "privacy",
                      "terms"
                    ]}
                    currentClassName="active"
                    offset={-500}
                  >
                    <li className="nav-item position-static">
                      <a
                        className="nav-link"
                        href="/"
                        onClick={onHome}
                        style={{ cursor: 'pointer' }}
                      >
                        Home
                      </a>
                    </li>
                    <li className="nav-item position-static">
                      <a
                        className="nav-link"
                        href="/privacy-policy"
                        onClick={onPrivacyPolicy}
                        style={{ cursor: 'pointer' }}
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className="nav-item position-static">
                      <a
                        className="nav-link"
                        href="/terms"
                        onClick={onTerms}
                        style={{ cursor: 'pointer' }}
                        
                      >
                        Terms of Service
                      </a>
                    </li>
                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
          <div className="right-widget">
          <ul className="d-flex align-items-center">
              <li>
                <div style={{marginRight: '12px'}}>
                  <a href="https://app.rdyai.com/login" className="nav-link signIn-action d-flex align-items-center">
                      login
                    </a>
                </div>
                
              </li>
              <li>
                <a href="https://app.rdyai.com/signup" className="nav-link demo-button">
                  Signup
                </a>  
              </li>
             
            </ul>
            
          </div>
        </div>
      </div>

      <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
        <div className="logo order-md-1">
          <img src={logo} alt="Rdy" width="140"/>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="images/rdy-icons/close.svg" alt="icon" />
          </div>
        </div>

        <Scrollspy
          className="navbar-nav"
          id="theme-menu-list"
          items={[
            "home",
            "how-it-works",
            "features",
            "pricing",
            "faq",
          ]}
          currentClassName="active"
          offset={-200}
        >
          <li className="nav-item">
            <a
              className="nav-link"
              href="/"
              onClick={onHome}
              style={{ cursor: 'pointer' }}
            >
              Home
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="/privacy-policy"
              onClick={onPrivacyPolicy}
              style={{ cursor: 'pointer' }}
            >
              Privacy Policy
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="/terms"
              onClick={onTerms}
              style={{ cursor: 'pointer' }}
              
            >
              Terms of Service
            </a>
          </li>
          <li className="nav-item">
            <a href="https://app.rdyai.com/login" className="nav-link">
              Login
            </a>
          </li>
          <li className="nav-item">
            <a href="https://app.rdyai.com/signup" className="nav-link">
              Signup
            </a>
          </li>
        </Scrollspy>
      </div>
    </>
  );
};

export default Header;
