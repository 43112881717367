import React from "react";

const FeatureContent = [
  {
    icon: "images/icon-product.png",
    meta: "Product Managers",
    subTitle: `Solidify your product requirement documents before project kickoff.`,
    dataDelay: "0",
    height: '70px',
  },
  {
    icon: "images/icon-computer.png",
    meta: "Software Engineers",
    subTitle: `Validate your design docs and quantify project resource needs.`,
    dataDelay: "100",
    height: '70px',
  },
  {
    icon: "images/icon-team.png",
    meta: "Anyone!",
    subTitle: `Evaluate a proposal to clarify understanding and expectations.`,
    dataDelay: "300",
    height: '70px',
  },
];

const FeatureBlocks = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-three">
            <div className="icon">
              <img src={val.icon} alt="icon" style={{height: val.height}} />
            </div>
            <h4 style={{ paddingTop: '16px'}}>{val.meta}</h4>
            <p className="font-rubik">{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureBlocks;
