import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>404 - Rdy AI</title>
      </Helmet>

      <div className="error-page d-lg-flex align-items-center">
        <div className="img-holder order-lg-last">
          <img
            src="images/404.png"
            alt="media"
            className="w-100 illustration"
          />
        </div>
        <div className="text-wrapper order-lg-first">
          <div className="logo">
            <Link to="/">
              <img src="images/logo-rdy-256.png" alt="Rdy" width="160" />
            </Link>
          </div>
          <h1 className="font-slab mt-40" style={{ fontSize: '70px' }}>
            You seem lost...
          </h1>
          <p className="font-rubik">
            Unfortunately there's nothing here. It's time to head home.
          </p>

          <Link
            to="/"
            className="back-home font-rubik d-flex align-items-center justify-content-center theme-btn-orange"
          >
            <span>Back to Home</span>
            <img src="images/rdy-icons/53.svg" alt="icon" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
