import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "How does Rdy work? ",
    desc: `We break down each of your projects into a problem and a set of solutions. With your interactions, we ask questions to evaluate the overall completion of your situation by highlighting aspects that may have been missed, and provide actionable feedback on how to improve. The goal is for you to be able to start a project being comfortable and confident that you've asked all the right questions, and have all the right answers.`,
    expand: "a",
  },
  {
    title: "At what point should I use Rdy?",
    desc: `While you can use Rdy at any point of a project, we're better used during the ideation and planning phase, before any major decisions have been made, and when ideas and solutions are still malleable.`,
    expand: "b",
  },
  {
    title: "How fast can I see results?",
    desc: `We move as fast as you do. You'll get constructive feedback on your first usage of Rdy, and as long as you iterate and make improvements in your project, we will be there by your side to help you.`,
    expand: "c",
  },
];
const FaqContentTwo = [
  {
    title: "Why do I need Rdy?",
    desc: `Let's be honest. Humans are fallible, we make mistakes, and we sometimes miss the most obvious. Our sole purpose is to help shine a light on the dark corners of project planning that could accidentally set us back at some point in the future. `,
    expand: "d",
  },
  {
    title: "Is this only for managers?",
    desc: `No, Rdy is geared towards every level of the organization. It's important for a project's success that everyone not only understands it, but has the means of asking the right questions where others have failed to do so. Whether you're overseeing the project or the one building it, we want to help you.`,
    expand: "e",
  },
  {
    title: "How safe is my information?",
    desc: `The safety and privacy of your data is our top priority. Read our Terms of Service for more details, but in summary, we will not keep any document related data longer than necessary for us to process it. Note that we do use OpenAI for parts of our internal works, so your usage of Rdy is subjective to their terms as well`,
    expand: "f",
  },
];

const Faq = () => {
  return (
    <div className="accordion-style-four">
      <div className="faq-wrraper">
        <div className="row">
          <div className="col-lg-6">
            <Accordion allowZeroExpanded>
              <div className="row">
                {FaqContent.map((item, i) => (
                  <div className="col-lg-12" key={i}>
                    <AccordionItem className="card">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="mb-0">
                            <button className="btn btn-link">
                              {item.title}
                            </button>{" "}
                          </h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel className="card-body fadeInUp">
                        <p>{item.desc}</p>
                      </AccordionItemPanel>

                    </AccordionItem>
                  </div>
                ))}
              </div>
            </Accordion>
          </div>
          {/* End.col */}

          <div className="col-lg-6">
            <Accordion allowZeroExpanded>
              <div className="row">
                {FaqContentTwo.map((item, i) => (
                  <div className="col-lg-12" key={i}>
                    <AccordionItem className="card">
                      <AccordionItemHeading className="card-header">
                        <AccordionItemButton>
                          <h5 className="mb-0">
                            <button className="btn btn-link">
                              {item.title}
                            </button>{" "}
                          </h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel className="card-body fadeInUp">
                        <p>{item.desc}</p>
                      </AccordionItemPanel>

                    </AccordionItem>
                  </div>
                ))}
              </div>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
