import React from "react";
import CtaForm from "./CtaForm";

const HeroBannerSeven = () => {
  return (
    <div className="hero-banner-seven lg-container">
      <div className="container">
        <div className="illustration-container">
          <img src="images/hero-main.png" alt="Rdy AI"/>
        </div>

        <div className="row hero-left">
          <div className="col-lg-6">
            <h1 className="hero-heading">
                It's time to <br/><span>deliver better</span>
            </h1>
            <p className="hero-sub-heading">
              We help make your project preparation perfect.
            </p>
            <CtaForm />
            <p className="term-text">
              Maximize your impact and save hours of productivity by working smarter.
            </p>
          </div>
        </div>
      </div>

      <div className="fancy-hero-two">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 m-auto">
                <p className="sub-heading">
                  Powered by an intelligent AI platform and years of industry experience
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBannerSeven;
